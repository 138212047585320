import React from 'react';

const AboutMe = () => {
    return (
        <div style={{
            paddingLeft: '1em',
            paddingRight: '1em',
            paddingTop: '3em',
        }}>
            <h3>Hey there 👋🏾,</h3>
            <p>I'm <strong>Timilehin Jegede</strong>, but you can call me <mark>Tim</mark>.</p>
            <ul>
                <li>I am a software engineer by profession, often playing around with Flutter and SwiftUI.</li>
                <li>Curious about my projects? Check out the cool stuff I've worked on and I am currently developing at <a href="https://timilehin.dev" target="_blank" rel="noopener noreferrer">timilehin.dev</a>.</li>
                <li>I enjoy playing classical pieces on the piano. Vivaldi’s “Four Seasons” is one of my favorite — it’s simply timeless!</li>
                <li>I love telling stories through pictures and films! - "Life in Frames"</li>
                <li>Life in frames because every moment has a story to tell. Check out my infrequent pictures and films at <a href="https://timilehin.pictures" target="_blank" rel="noopener noreferrer">timilehin.pictures</a>.</li>
                <li>Pushing "ELOs" on chess.com, I play the Vienna Gambit as white and the King's Indian Attack as black.</li>
                <li>I play video games but don't get to play as often as I'd like. Currently, I'm on Ghost of Tsushima – stuck at defeating Ryuzo, leader of the Straw Hats.</li>
                <li>I’ve recently started reading "Atomic Habits" by James Clear. Next on my reading list is "Outliers" by Malcolm Gladwell.</li>
                <li>I also write about software and projects I am building. Interested? Check out my blog at <a href="https://timilehin.blog" target="_blank" rel="noopener noreferrer">timilehin.blog</a>.</li>
            </ul>
            <h4>Want to get in touch?</h4>
            <ul>
                <li><a href="mailto:hello@timilehin.me">Email</a></li>
                <li><a href="https://twitter.com/timilehinjegede" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                <li><a href="https://www.linkedin.com/in/timilehinjegede/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                <li><a href="https://github.com/timilehinjegede" target="_blank" rel="noopener noreferrer">GitHub</a></li>
                <li><a href="https://stackoverflow.com/users/13452640/v-n?tab=profile" target="_blank" rel="noopener noreferrer">StackOverflow</a></li>
                <li><a href="https://www.chess.com/member/j_timi" target="_blank" rel="noopener noreferrer">Chess.com</a></li>
                <li><a href="https://psnprofiles.com/j_tim_" target="_blank" rel="noopener noreferrer">PSN</a></li>
            </ul>
        </div>
    );
};

export default AboutMe;
