import React, {useEffect} from 'react';
import AboutMe from './components/AboutMe';
import './App.css';
import axios from 'axios';

const App = () => {

    useEffect(() => {
        const sendVisitorInfo = async () => {
            try {
                const response = await axios.get('https://ipapi.co/json/');
                const visitorInfo = {
                    ip: response.data.ip,
                    city: response.data.city,
                    region: response.data.region + ', ' + response.data.country_capital,
                    country: response.data.country_name,
                    userAgent: navigator.userAgent,
                    referrer: document.referrer,
                    portfolio_name: "timilehin.me",
                };

                console.log('Visitor info:', visitorInfo);
                await axios.post('https://us-central1-tim-portfolios.cloudfunctions.net/newVisitorNotification', visitorInfo);
            } catch (error) {
                console.error('Error sending visitor info:', error);
            }
        };

        sendVisitorInfo();
    }, []);

  return (
      <div className="App">
        <AboutMe />
      </div>
  );
};

export default App;
